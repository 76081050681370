// prefer default export if available
const preferDefault = m => (m && m.default) || m

exports.components = {
  "component---src-pages-404-tsx": () => import("./../../../src/pages/404.tsx" /* webpackChunkName: "component---src-pages-404-tsx" */),
  "component---src-pages-blog-tsx": () => import("./../../../src/pages/blog.tsx" /* webpackChunkName: "component---src-pages-blog-tsx" */),
  "component---src-pages-calculator-tsx": () => import("./../../../src/pages/calculator.tsx" /* webpackChunkName: "component---src-pages-calculator-tsx" */),
  "component---src-pages-contact-tsx": () => import("./../../../src/pages/contact.tsx" /* webpackChunkName: "component---src-pages-contact-tsx" */),
  "component---src-pages-findabroker-tsx": () => import("./../../../src/pages/findabroker.tsx" /* webpackChunkName: "component---src-pages-findabroker-tsx" */),
  "component---src-pages-findasolicitor-tsx": () => import("./../../../src/pages/findasolicitor.tsx" /* webpackChunkName: "component---src-pages-findasolicitor-tsx" */),
  "component---src-pages-findasurveyor-tsx": () => import("./../../../src/pages/findasurveyor.tsx" /* webpackChunkName: "component---src-pages-findasurveyor-tsx" */),
  "component---src-pages-index-tsx": () => import("./../../../src/pages/index.tsx" /* webpackChunkName: "component---src-pages-index-tsx" */),
  "component---src-templates-blog-post-index-tsx": () => import("./../../../src/templates/BlogPost/index.tsx" /* webpackChunkName: "component---src-templates-blog-post-index-tsx" */),
  "component---src-templates-how-to-step-index-tsx": () => import("./../../../src/templates/HowToStep/index.tsx" /* webpackChunkName: "component---src-templates-how-to-step-index-tsx" */),
  "component---src-templates-rate-change-index-tsx": () => import("./../../../src/templates/RateChange/index.tsx" /* webpackChunkName: "component---src-templates-rate-change-index-tsx" */)
}

